<template>
  <a-card>
    <div class="query-wrap">
      <a-form-model layout="inline" :model="queryParam" v-bind="{}" ref="queryForm">
        <a-form-model-item label="业务类型" prop="businessType">
          <a-select v-model="queryParam.businessType" style="width: 120px" placeholder="请选择">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="1">碧园召回</a-select-option>
            <a-select-option :value="2">新文重推</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="数据类型" prop="dataType">
          <a-select v-model="queryParam.dataType" style="width: 120px" placeholder="请选择">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="1">已下单</a-select-option>
            <a-select-option :value="2">未下单</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="时间段">
          <a-range-picker @change="onChangeDatetime" v-model="datetime" :format="format" :show-time="{ format: format.split(' ')[1] }" :placeholder="['开始时间', '结束时间']" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{}">
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button type="default" @click="reset">清空</a-button>
            <a-button type="danger" icon="download" @click="download" :loading="downloading" v-if="$checkPermission('Action:ExternalData:Export')">导出</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <s-table ref="table" size="default" :rowKey="record => record.id" :columns="columns" :data="loadData"></s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'

export default {
  components: {
    STable
  },
  data() {
    return {
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          width: 180
        },
        {
          title: '业务类型',
          dataIndex: 'businessType',
          width: 90
        },
        {
          title: '数据类型',
          dataIndex: 'dataType',
          width: 90
        },
        {
          title: '批次号',
          dataIndex: 'batchId',
          width: 100
        },
        {
          title: '时间',
          dataIndex: 'datetime',
          width: 160
        },
        {
          title: '姓名',
          dataIndex: 'name',
          customRender: text => text + '**',
          width: 60
        },
        {
          title: '性别',
          dataIndex: 'sex',
          width: 60
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: 115
        },
        {
          title: '运营商',
          dataIndex: 'operator',
          width: 80
        },
        {
          title: '订单编号',
          dataIndex: 'orderId',
          width: 100
        },
        {
          title: '省',
          dataIndex: 'province',
          width: 100
        },
        {
          title: '市',
          dataIndex: 'city',
          width: 100
        },
        {
          title: '地区',
          dataIndex: 'region',
          width: 100
        },
        {
          title: '详细地址',
          dataIndex: 'address',
          width: 250
        }
      ],
      format: 'YYYY-MM-DD HH:mm:ss',
      datetime: undefined,
      downloading: false,
      // 查询条件参数
      queryParam: {
        businessType: undefined,
        dataType: undefined,
        minId: undefined,
        startTime: undefined,
        endTime: undefined
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const params = Object.assign(parameter, this.queryParam)

        return this.$http.get('/external/data/findAll', { params }).then(res => {
          return res.body
        })
      }
    }
  },
  methods: {
    onChangeDatetime(_date, dateString) {
      this.queryParam.startTime = dateString[0] || undefined
      this.queryParam.endTime = dateString[1] || undefined
    },
    query() {
      this.$refs.table.refresh()
    },
    reset() {
      this.datetime = []
      this.onChangeDatetime(null, [null, null])
      this.$refs.queryForm.resetFields()
      this.$refs.table.refresh()
    },
    download() {
      this.downloading = true
      this.$http
        .get('/external/data/exportExcel', { params: this.queryParam, responseType: 'blob', download: true, timeout: 0 })
        .then(data => {
          console.log('blob', data)
        })
        .finally(() => (this.downloading = false))
    }
  }
}
</script>

<style scoped>
.query-wrap {
  padding-bottom: 20px;
}
</style>
